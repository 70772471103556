import gsap, {Power3} from "gsap"

export default function enterAnimation(component, componentTypo){

        // ENTRÉE EN SCENE DES PHOTOS
        let gridItems = gsap.utils.toArray(` .${component} .grid__item-img`)
        let tl = gsap.timeline()
        tl.fromTo(gridItems, 
            {
                height: 0,
                backgroundSize:'80%',
                backgroundPosition:'cover'
            }
            ,{
                duration: 2,
                ease: Power3.easeOut,
                stagger: {amount: 0.6, grid: 'auto', from: 'center'},
                height: '100%',
                backgroundSize:'100%',
            },
        0)

}