import React, { useEffect} from 'react'


// GSAP components
import { gsap,Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

//Framer-Motion components
import {motion} from 'framer-motion'

// Styling
import '../Utilitaries/Services.css'
import "./Homepage.css"

// Page components
import Navbar from '../Utilitaries/Navbar'
import HeroBanner from './HeroBanner'
import Description from './Description'
import RealGrid from '../RealGrid/RealGrid/RealGrid'

export default function Homepage(){

    useEffect(() => {
        const vh = (coef) => window.innerHeight * (coef/100);
        gsap.registerPlugin(ScrollTrigger)

        // Change la backgroundColor avec le scroll
        gsap.to('.Homepage', {
            backgroundColor : '#D2C2BA',
            ease: Power3.easeIn,
            scrollTrigger : {
                trigger : '.Homepage',
                end : 'bottom-=' + vh(200),
                id : 'backgroundHP',
                scrub: 1,
            }
        })

        // SNAPPING DU SCROLL :) 
        gsap.to('.Homepage',{
            scrollTrigger: {
                trigger : '.Homepage',
                start : 'top top',
                end : 'bottom bottom',
                snap :  {
                    snapTo: 1/2,
                    duration: {min: 0.2, max: 3},
                    delay: 0.01,
                    ease: "Power3.easeOut",
                }
            }
        })


    }, [])
    
    return (
        <div className = "Homepage ">
            <Navbar color = '#9AA0A1'/>
            <motion.div
                animate = {{opacity: 1, x: 0, y: 0}}
                exit = {{x:'-100vw', y: '-100vh'}} 
                transition = {{ease:Power3.easeIn, duration:1}} 
            >
                
                <HeroBanner />
                <Description />
            </motion.div>
            <RealGrid />
                
        </div>
    )
    
}

