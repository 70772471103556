import React, {useEffect, useState, useCallback} from 'react'

// COMPONENTS 
import GridItem from '../GridItem/GridItem'
import enterAnimation from '../../Utilitaries/enterAnimation'
import Visio from '../Visio/Visio'


// GSAP
import gsap, {Power3} from 'gsap'
import { ScrollTrigger } from 'gsap/all'

//Framer-Motion components
import {motion} from 'framer-motion'

// React-Router-Dom
import { HashLink as Link } from 'react-router-hash-link';

// UTILITARIES
import Skew from '../../Utilitaries/Skew';
import HorizontalScroll from '../../Utilitaries/HorizontalScroll'

import {withStyles} from '@material-ui/styles'

// STYLING
import './Gallery.css'
import '../../Utilitaries/Services.css'
import photos from '../../Utilitaries/photos';

const styles = {
    Visio : {
        width: '40vw',
        height: '100vh',
        right: '-40vw',
        backgroundColor: 'salmon',
        zIndex: '1'
    }
}

function Gallery(props){
    const [visio, setVisio] = useState(false);
    const [current, setCurrent] = useState(0)
    

    // make wrapper function to give child
    const wrapperSetVisio = useCallback(val => {
        setVisio(val);
    }, [setVisio]);

    const wrapperSetCurrent = useCallback(val => {
        setCurrent(val);
    }, [setCurrent]);

    if (visio){
        gsap.to('.Visio', {
            xPercent: -100,
            ease: Power3.easeOut,
            duration: 1
        })
    }else{
        gsap.to('.Visio', {
            xPercent:100,
            ease: Power3.easeOut,
            duration: 1.5,
        })
    }
    
    useEffect(() => {
        gsap.registerPlugin (ScrollTrigger)
        HorizontalScroll()
        enterAnimation('Gallery')
        Skew()
     
        // APPARITION DE LA PETITE NAV
        gsap.from('.Gallery .navGallery',{
            x: '-200px',
            duration: 0.5,
            delay: 1,
            opacity: 0,
            stagger : {
                from: 'start',
                amount: 0.2,
                ease: Power3.easeOut
            },   
        })

        let animatedCliches = gsap.utils.toArray('.animate .grid__item-img')
        let offsets = animatedCliches.map( element =>  element.parentNode.offsetLeft );

        // Animer quelques unes des photos via le scroll
        animatedCliches.forEach( (cliche, idx) => {
            gsap.to(cliche,
            {
                width: '110%',
                ease: Power3.easeIn,
                duration : 1.2,
                scrollTrigger : {
                    trigger : '.Gallery',
                    start : offsets[idx] - window.innerWidth  ,
                    id: `${idx}`,
                    scrub:1
                }
            })
        })


    }, [])

    return (
        <div 
            className = "panel Gallery" 
            id = "Gallery"
        >
                {/* DIV DE LA VISIONNEUSE PRINCIPALE */}
            <Visio setVisio = {wrapperSetVisio}  setCurrent = {wrapperSetCurrent} current = {current} photos = {photos}/>

            <motion.div 
                className = {`gridContainer relative ${visio ? "flex" :  "" }`}
                // initial = {{x: '50vw', opacity: 0}}
                // animate = {{x: '-50vw', opacity: 1}}
                transition = {{duration : 1, ease : Power3.ease }}
            >   
                {/* NAVBAR ON THE LEFT */}
                <div className = "navGallery backButton fixed ">
                    <Link to = "/#RealGrid">
                        BAAAAAAACK
                    </Link>
                </div>

                <div onClick = {() => setVisio(false)} className = "navGallery mode2 fixed">
                    02
                </div>
                <div onClick = {() => setVisio(true)} className = "navGallery mode1 fixed">
                    01
                </div>

                {/* GRID DES PHOTOS  */}
                <motion.div 
                    className = "grid"
                    exit = {{x:'50vw', opacity : 0}}
                    transition = {{duration : 1, ease : Power3.ease}}
                >

                    {photos.map((el, idx) => (
                      <GridItem 
                        photo = {el.image} 
                        position = {`pos-${idx + 1}`} 
                        key = {el.name} 
                        id = {idx} 
                        setCurrent = {wrapperSetCurrent}
                        setVisio = {wrapperSetVisio} 
                        />
                    ))}
                 
                </motion.div>
            </motion.div>
          

        </div>
    )
}

export default withStyles(styles)(Gallery)