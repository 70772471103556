import React from 'react'

// STYLING 
import './GridItem.css'

export default function GridItem(props){

    const setCurrent = props.setCurrent
    const setVisio = props.setVisio
    
    const triggerVisio = () => {
        setCurrent(props.id);
        setVisio(true)
    }

    return (
        <div className ={`grid__item ${props.position} ${props.animate}`} onClick = {setCurrent ? triggerVisio : null}>
            <div 
                className="grid__item-img" 
                style={{
                    backgroundImage: `url(${props.photo})`
                }}>
                
            </div>
        </div>
    )
}