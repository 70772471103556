import React, {Component} from 'react'

import GridItem from '../RealGrid/GridItem/GridItem'

// GSAP COMPONENTS
import gsap ,{Power3}from 'gsap'
import { ScrollTrigger } from 'gsap/all'

// STYLING
import "./Description.css"
import "../Utilitaries/Services.css"

// PHOTOS
import fenetre from '../assets/photos/fenetre.jpg'
import autoroute from '../assets/photos/autoroute.jpg'
import train from '../assets/photos/train.jpg'

class Description extends Component{

    componentDidMount(){
        gsap.registerPlugin(ScrollTrigger)

        // Je change la couleur du texte avec le scroll
        gsap.to('.Description section', {
            color: '#FFFFFF',
            scrollTrigger : {
                trigger : '.Description',
                start: 'top top+=100px',
                end :'bottom bottom',
                scrub: 1
            }
        })

        let photos = gsap.utils.toArray('.Description .grid__item');
     
        gsap.to(photos, 
            {
                ease: Power3.easeOut,
                stagger: true,
                height: '100%',
                width: '100%',
                backgroundSize:'100%',
                duration: 3,
                scrollTrigger:{
                    trigger : '.Description',
                    start: 'top center',
                    end : 'center+=30px center',
                    id: "description",
                    // scrub: 1,
                    toggleActions :'play none none none'
                }
            },
        )
    }

    render(){
        return (
            <div className = "panel Description"> 
                <div className = "grid">
                    <section>
                        <h2>Mono No Aware</h2>
                        <p>
                        is a Japanese term for the awareness 
                        of impermanence (無常, mujō), or transience of things, and both a transient gentle sadness (or 
                        wistfulness) at their passing as well as a longer, deeper gentle sadness about this state being 
                        the reality of life.
                        </p>
                    </section>

                    <GridItem photo = {train} position= "pos-1"/>
                    <GridItem photo = {fenetre} position= "pos-2"/>
                    <GridItem photo = {autoroute} position= "pos-3"/>
                </div>
            </div>
        )
    }
}

export default Description