import { Component } from 'react'

// Components
import PhotoGridComponent from './PhotoGridComponent'

// GSAP function
import flip from '../Utilitaries/flip'
import {gsap, Power3} from 'gsap'

//Framer-motion components
import {motion} from 'framer-motion'

//Styling 
import '../Utilitaries/Services.css'
import './PhotoGrid.css'

//Photos 
import jumping from "../assets/photos/eliott2.jpg";
import fenetre from "../assets/photos/fenetre.jpg";
import pinkyLady from "../assets/photos/pinky_ladyyyyy.jpg"
import oie from "../assets/photos/oie.jpg"
import rose from "../assets/photos/rose.jpg"
import pigeon from "../assets/photos/pigeon.jpg"
import pecheurs from "../assets/photos/pecheurs.jpg"
import louise from "../assets/photos/louise.jpg"


class PhotoGrid extends Component {
    constructor(props){
        super(props)
        this.state = {
            vision: false,
            mainPhoto : jumping
        }
        this.handleClick = this.handleClick.bind(this)
        this.changeMainPhoto = this.changeMainPhoto.bind(this)
        
    }

    changeGrid(elements){
        elements.forEach(e => {
            e.classList.toggle('vision');
            e.classList.toggle('grid')
        })
    }

    handleClick(e){
        this.setState({
            vision: !this.state.vision
        })
        const photos = document.querySelectorAll('.PhotoGridComponent')
        flip(photos, (photos) => {
            photos.forEach(e => {
                e.classList.toggle('vision');
                e.classList.toggle('grid')
            })
        }, {duration:1, ease: 'Power3.easeOut'}) 
        

        gsap.from(".MainPhoto",{
            x:"50vw",
            opacity:0
        })
        
    }

    changeMainPhoto(newPhoto){
        this.setState({
            mainPhoto : newPhoto
        })
    }

    render(){
        const {vision,mainPhoto} = this.state
      

        return (
            <motion.div 
                className = "PhotoGrid relative"
                initial = {{y:'100px', opacity:0}}
                animate = {{y:'0', opacity:1}}
                transition = {{ease:Power3.easeOut, duration:0.5}}
            >  

                <div className = "row relative">
                    <PhotoGridComponent  
                        photo = {jumping} x
                        titre = "Elliot's tattoo" 
                        lieux = "2019 - France" 
                        visionOn = {this.handleClick} 
                        vision = {vision}
                        changeMainPhoto = {this.changeMainPhoto}
                    />
                    <div  className = "grid-space"></div>
                    <PhotoGridComponent  
                        photo = {`${fenetre}`} 
                        titre = "Lorem Ipsum" 
                        lieux = "2020 - Paris" 
                        visionOn = {this.handleClick} 
                        vision = {vision}
                        changeMainPhoto = {this.changeMainPhoto}
                        />
                    <div className = "grid-space"></div>
                    <PhotoGridComponent  
                        photo = {`${pinkyLady}`} 
                        titre = "Lorem Ipsum" 
                        lieux = "2020 - Paris" 
                        visionOn = {this.handleClick} 
                        vision = {vision}
                        changeMainPhoto = {this.changeMainPhoto}

                        />
                </div>

                <div className = "row">
                    <div className = "grid-space"></div>
                    <PhotoGridComponent  
                        photo = {`${oie}`} 
                        titre = "Lorem Ipsum" 
                        lieux = "2020 - Paris" 
                        visionOn = {this.handleClick} 
                        vision = {vision}
                        changeMainPhoto = {this.changeMainPhoto}
                        />
                    <div className = "grid-space"></div>
                    <PhotoGridComponent  
                        photo = {`${rose}`} 
                        titre = "Lorem Ipsum" 
                        lieux = "2020 - Paris" 
                        visionOn = {this.handleClick} 
                        vision = {vision}
                        changeMainPhoto = {this.changeMainPhoto}
                        />
                </div>

                <div className = "row">
                    <PhotoGridComponent  
                        photo = {`${pigeon}`} 
                        titre = "Lorem Ipsum" 
                        lieux = "2020 - Paris" 
                        visionOn = {this.handleClick} 
                        vision = {vision}
                        changeMainPhoto = {this.changeMainPhoto}
                    />
                    <div className = "grid-space"></div>
                    <PhotoGridComponent  
                        photo = {`${pecheurs}`} 
                        titre = "Lorem Ipsum" 
                        lieux = "2020 - Paris" 
                        visionOn = {this.handleClick} 
                        vision = {vision}
                        changeMainPhoto = {this.changeMainPhoto}
                        />
                    <div className = "grid-space"></div>
                    <PhotoGridComponent  
                        photo = {`${louise}`} 
                        titre = "Lorem Ipsum" 
                        lieux = "2020 - Paris" 
                        visionOn = {this.handleClick} 
                        vision = {vision}
                        changeMainPhoto = {this.changeMainPhoto}
                        />
                </div>
                
                <div className={`MainPhoto flex ${!this.state.vision && 'hidden'}`}>
                    <div 
                        className = "Photo-Container" 
                        style = {{backgroundImage: mainPhoto}} 
                    > 
                    </div>
                </div>

            </motion.div>
        )
    }
}

export default PhotoGrid