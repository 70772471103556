import  {Component} from 'react'

// Styling
import '../Utilitaries/Services.css'
import './PhotoGridComponent.css'

class PhotoGridComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            kingSize: false
        }
        this.handleHover = this.handleHover.bind(this)
        this.handleMouseLeave = this.handleMouseLeave.bind(this)
    }

    handleHover(e){
        // console.log(this.props.vision)

        // if (this.props.vision){
        //     this.setState({
        //         kingSize: true
        //     })
        // } else {
        //     this.setState({
        //         kingSize: false
        //     })
        // }
        console.log(e.target.style.backgroundImage)
        this.props.changeMainPhoto(e.target.style.backgroundImage)


    }

    handleMouseLeave(){
        this.setState({
            kingSize: false
        })
    }

    render(){
        const {photo, titre, lieux, visionOn} = this.props;
       
        return (
            
            <div className = "PhotoGridComponent relative flex grid">
                <div   
                    className = "Photo" 
                    style = {{backgroundImage: `url(${photo})`}} 
                    onClick={visionOn}
                    onMouseEnter =  {this.handleHover}
                    onMouseLeave = {this.handleMouseLeave}
                    

                >       
                </div>

                <div className = "PhotoGrid-Legende flex">
                    <h4>{titre}</h4>
                    <p> {lieux} </p>
                </div>

                {/* <div className={`PhotoKingSize ${!this.state.kingSize && 'hidden'}`}>
                    <img className= "resp" src = {photo}/>  
                </div> */}
            </div>
            
        )
    }
}

export default PhotoGridComponent
