import React, {useEffect} from 'react'

// GSAP COMPONENTS
import gsap ,{Power3}from 'gsap'
import { ScrollTrigger } from 'gsap/all'

//Framer-Motion components
import {motion} from 'framer-motion'

// COMPONENTS
import GridItem from '../GridItem/GridItem'
import Move from '../../Utilitaries/Move'

// ROUTER
import { Link } from 'react-router-dom'

// ASSETS 
import pecheurs from '../../assets/photos/pecheurs.jpg'
import eliott2 from '../../assets/photos/eliott2.jpg'
import fleurs from '../../assets/photos/fleurs.jpg'
import lucie from '../../assets/photos/lucie.jpg'
import train from '../../assets/photos/train.jpg'
import autoportrait from '../../assets/photos/autoportrait.jpg'
import louise from '../../assets/photos/louise.jpg'
import lac from '../../assets/photos/lac.jpg'

// STYLING 
import '../../Utilitaries/Services.css'
import './RealGrid.css'

export default function RealGrid(props){

    // FONCTION ANIMANT LES IMAGES AVEC LE MOUVEMENT DE LA SOURIS
    Move('RealGrid')

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)

        // FONCTION GÉRANT L'ENTRÉE EN SCÈNE DES PHOTOS ET DU WORDING
        let tl = gsap.timeline({
            scrollTrigger : {
                trigger :'.RealGrid',
                start : 'top center',
                toggleActions : 'play none none reverse',
            }
        }); 

        let gridItems = gsap.utils.toArray(` .RealGrid .grid__item-img`)
        tl.to(gridItems, 
            {
                duration: 2,
                ease: Power3.easeOut,
                stagger: {amount: 0.6, grid: 'auto', from: 'center'},
                height: '100%',
                backgroundSize:'100%',
            },
        0)

        tl.from('.wordTitle', {
            y:'100%',
            duration:1,
            ease: Power3.easeOut
        },
        1)


    }, [])

    return (
            <div id = "RealGrid" className = "panel RealGrid relative">

                <motion.div 
                    className = 'BackgroundExit'
                    initial = {{x:0}}
                    animate = {{x: '100vw'}}
                    exit = {{x:0}} 
                    transition = {{ease:Power3.easeIn, duration:1.5}}
                >
                </motion.div>
                <motion.div 
                    className="grid"
                    animate = {{x:0, opacity:1}}
                    exit = {{x:'-100vw', opacity:0}} 
                    transition = {{ease:Power3.easeIn, duration:1, delay : 1}}
                >
                    <h1> <Link to = '/gallery'><span className = "hidingSpan "><span className = "wordTitle">View Gallery </span></span> </Link> </h1> 
                    <GridItem photo = {pecheurs} position= "pos-1"/>
                    <GridItem photo = {eliott2} position = "pos-2"/>
                    <GridItem photo = {fleurs} position = "pos-3"/>
                    <GridItem photo = {lucie} position = "pos-4"/>
                    <GridItem photo = {train} position = "pos-5"/>
                    <GridItem photo = {autoportrait} position = "pos-6"/>
                    <GridItem photo = {louise} position = "pos-8"/>
                    <GridItem photo = {lac} position = "pos-7"/>
                </motion.div> 
            </div>
    )
} 