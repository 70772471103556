import React, {
  Component
} from 'react'

// GSAP 
import {
  gsap,
  Power3
} from 'gsap'

// Fonction "GetLines" 
import getLines from '../Utilitaries/getLines'

// Styling 
import "../Utilitaries/Services.css"
import "./IntroductionHero.css"

class IntroductionHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lines: [],
      tl: new gsap.timeline()
    }
  }

  componentDidMount() {
    let words = getLines()
    this.setState({
      lines: words
    })
  }

  componentDidUpdate() {
    let tl = this.state.tl
    let secondary = gsap.utils.toArray('.secondary')

    tl.from(this.state.lines, {
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.05,
      ease: Power3.easeOut
    })

    tl.from(secondary, {
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.05,
      ease: Power3.easeOut
    }, "-=0.8");

    console.log('ça update ?')
  }

  componentWillUnmount() {
    let tl = this.state.tl;
    tl.pause()
  }

  render() {
      return ( <
          div className = "IntroductionHero flex" >
          <
          div className = "IntroductionHero-Line row" >
          <
          div className = "IntroductionHero-Main" >
          <
          h1 > Lorem Ipsum is simply dummy text of the printing and typesetting industry.psum is simply dummy < /h1> <
          /div>

          <
          div className = "IntroductionHero-Secondary flex" >
          <
          div className = "Collection" >
          <
          div className = "overflowHidden" >
          <
          h2 className = "secondary" > S2021 #2022 </h2>
                            </div>
                            <div className = "overflowHidden" >
          <
          h3 className = "secondary" > {
            `<26YO/orange/vLan/Paow>`
          } < /h3> <
          /div> <
          div className = "overflowHidden" >
          <
          h4 className = "secondary" > {
            `<26YO/orange/vLan/Paow>`
          } < /h4> <
          /div> <
          div className = "overflowHidden" >
          <
          h5 className = "secondary" > {
            `<26YO/orange/vLan/Paow>`
          } < /h5> <
          /div>


          <
          /div>

          <
          div className = "PageNumber" >
          <
          p > #1 </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default IntroductionHero;