// GSAP
import gsap, {Power3} from 'gsap'
import { ScrollTrigger } from 'gsap/all'

//  OKAY NEW PROJECT I'M GONNA TRY TO ADD SKEW TO THE PICTURES AS WE SCROLL
export default function Skew(){
    gsap.registerPlugin(ScrollTrigger)

    let proxy = { skew: 0 },
    skewSetter = gsap.quickSetter(".grid__item", "skewX", "deg"), // fast
    clamp = gsap.utils.clamp(-2, 2); // don't let the skew go beyond 20 degrees.

    ScrollTrigger.create({
        onUpdate: (self) => {
            let skew = clamp(self.getVelocity() / -300);
            // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
            if (Math.abs(skew) > Math.abs(proxy.skew)) {
                proxy.skew = skew;
                gsap.to(proxy, {skew: 0, duration: 0.8, ease: Power3.ease , overwrite: true, onUpdate: () => skewSetter(proxy.skew)});
            }
        }
    });

    gsap.set(".grid__item", {transformOrigin: "right center", force3D: true});
    // OH MY GOSH IT WORKED RIGHT AWAY
}
 