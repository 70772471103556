// GSAP
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'

export default function HorizontalScroll(){
    gsap.registerPlugin(ScrollTrigger)

    let gridWidth = document.querySelector('.gridContainer').offsetWidth
    let width = document.querySelector('body').offsetWidth

    // Horizontal Scroll
    gsap.to('.grid',{
        x: () => -(gridWidth - width ) + 250 + 'px',
        scrollTrigger: {
            trigger: '.gridContainer',
            scrub: 0.5, 
            // snap: 1/10,
            pin: true,
            end: () => '+=' + gridWidth,
            id: 'horizontalScroll'
        }
    })
}
