import sunset from '../assets/photos/sunset.jpg'
import pigeon from '../assets/photos/pigeon.jpg'
import pinky_ladyyyyy from '../assets/photos/pinky_ladyyyyy.jpg'
import poisson from '../assets/photos/poisson.jpg'
import train from '../assets/photos/train.jpg'
import vert from '../assets/photos/vert.jpg'
import louise from '../assets/photos/louise.jpg'
import lac from '../assets/photos/lac.jpg'
import oie from '../assets/photos/oie.jpg'
import autoportrait from '../assets/photos/autoportrait.jpg'
import autoportrait2 from '../assets/photos/autoportrait2.jpg'
import baigneur from '../assets/photos/baigneur.jpg'
import cakes from '../assets/photos/cakes.jpg'
import canap from '../assets/photos/canap.jpg'
import eliott from '../assets/photos/eliott.jpg'
import eliott2 from '../assets/photos/eliott2.jpg'
import fenetre from '../assets/photos/fenetre.jpg'
import feux_rouges from '../assets/photos/feux_rouges.jpg'
import fleurs from '../assets/photos/fleurs.jpg'
import jules from '../assets/photos/jules.jpg'
import lucie from '../assets/photos/lucie.jpg'
import neige from '../assets/photos/neige.jpg'
import pecheurs from '../assets/photos/pecheurs.jpg'
import rose from '../assets/photos/rose.jpg'
import train2 from '../assets/photos/train2.jpg'
import autoroute from '../assets/photos/autoroute.jpg'
import autoportrait3 from '../assets/photos/autoportrait3.jpg'


let photos = []


export default photos = [
    {
        name: "sunset",
        image: sunset,
        date: '01.01.2020'
    },{
        name: "le pigeon",
        image: pigeon,    
        date: '02.02.2021'
    },{
        name: "pinky",
        image: pinky_ladyyyyy,    
        date: '25.02.2021'
    },{
        name: "poisson",
        image: poisson,    
        date: '24.01.2021'
    },{
        name: "train",
        image: train,
        date: '22.12.2020'
    },{
        name: "vert",
        image: vert,
        date: '07.02.2021'
    },{
        name: "louise",
        image: louise,
        date: '09.02.2021'
    },{
        name: "lac",
        image: lac,
        date: '16.02.2021'
        
    },{
        name: "oie",
        image: oie,
        date: '25.01.2021'
        
    },{
        name: "autoportrait",
        image: autoportrait,
        date: '01.01.2020'
        
    },{
        name: "autoportrait2",
        image: autoportrait2,  
        date: '18.02.2021'
    },{
        name: "baigneur",
        image: baigneur,
        date: '01.03.2021'
    },{
        name: "cakes",
        image: cakes,
        date: '05.01.2021'
    },{
        name: "canap",
        image: canap,
        date: '27.02.2021'
    },{
        name: "eliott",
        image: eliott,
        date: '14.01.2021'
    },{
        name: "eliott2",
        image: eliott2,
        date: '18.01.2021'
    },{
        name: "fenetre",
        image: fenetre,
        date: '03.01.2021'
    },{
        name: "feux rouges",
        image: feux_rouges,
        date: '26.12.2020'
    },{
        name: "fleurs",
        image: fleurs,
        date: '13.02.2021'
    },{
        name: "jules",
        image: jules,
        date: '03.02.2021'
    },{
        name: "lucie",
        image: lucie,
        date: '12.02.2021'
    },{
        name: "neige",
        image: neige,
        date: '10.01.2021'
    },{
        name: "pecheurs",
        image: pecheurs,
        date: '23.01.2021'
    },{
        name: "rose",
        image: rose,
        date: '10.02.2021'
    },{
        name: "train de nuit",
        image: train2,
        date: '02.01.2021'
    },{
        name: "autoroute",
        image: autoroute,
        date: '03.04.2021'
    },{
        name: "autoportrait #3",
        image: autoportrait,
        date: '01.01.2020'
    }
]

