import React from 'react'

// REACT-ROUTER-DOM
import { Link } from 'react-router-dom';

// COMPONENTS
import GridItem from '../GridItem/GridItem';
import gsap, {Power3} from 'gsap';

// STYLING
import './Visio.css'
import '../../Utilitaries/Services.css'
import photos from "../../Utilitaries/photos";

export default function Vision(props){
    const current = props.current
    const setCurrent = props.setCurrent
    const length = photos.length;
    const tl = gsap.timeline()

    tl.from('.movingWords', {
        yPercent : 100,
        ease: Power3.easeOut,
        duration: 1
    })
    
    const nextPhoto = () => {
        setCurrent(current === length - 1 ? 0 : current + 1)
        tl.restart()
    }   


    return (<div className = "fixed Visio">
        <div className = "NavGallery flex">
            <div className = "backButton navButton">
                <Link to = "/#RealGrid">
                    BAAAAAAACK
                </Link>
            </div>

            <div onClick = {() => props.setVisio(false)} className = "mode2 navButton">
                02
            </div>
            <div onClick = {() => props.setVisio(true)} className = "mode1 navButton">
                01
            </div>
        </div>

        <section>
            {props.photos.map((el, idx) => {
                if (idx === current){
                    return (
                        <div key = {idx} className = "test">
                            <div className='Legende'>
                                <h2><span className = "hidingSpan"> <span className = "movingWords">{el.name}</span> </span></h2>
                                <p> <span className = "hidingSpan"> <span className = "movingWords">{el.date}</span> </span></p>
                            </div>
                            <GridItem photo = {el.image} position = {`inVisio main`} key = {el.name}/>
                        </div>
                    )}

               

                else if (idx === current + 1 || current === length - 1){
                    if (current === length - 1 && idx === 0){
                        return <GridItem photo = {el.image} position = {`inVisio secondary`} key = {el.name} onClick = {nextPhoto}/>
                    }
                    else if (idx === current + 1){
                        return <GridItem photo = {el.image} position = {`inVisio secondary`} key = {el.name} onClick = {nextPhoto}/>
                    }
                }
                else if (idx !== current && idx !== current + 1){
                    return null
                }  

                return null
            })}
            <div className = "secondary clickable " onClick = {nextPhoto}></div>
        </section>
    </div>)
}