import {useEffect} from 'react'

// Components
import Navbar from '../Utilitaries/Navbar'
import GridItem from '../RealGrid/GridItem/GridItem'


// GSAP COMPONENTS
import gsap ,{Power3} from 'gsap'

//Framer-Motion components
import {motion} from 'framer-motion'

// STYLING
import { IoLogoInstagram } from 'react-icons/io'
import { AiOutlineInstagram } from 'react-icons/ai'
import './About.css'
import '../Utilitaries/Services.css'
import enterAnimation from '../Utilitaries/enterAnimation'
import autoportrait3 from '../assets/photos/autoportrait3.jpg'


export default function About(){
    // Move('About')

    useEffect(() => {
        enterAnimation('About')
        const wording = gsap.utils.toArray('.word')
        gsap.from(wording,{
            yPercent: 100,
            ease: Power3.easeOut,
            stagger: 0.1,
            duration: '1'
        })

    }, [])

    return (
        <motion.div
                animate = {{opacity: 1, x: 0, y: 0}}
                exit = {{x:'100vw', opacity: 0}} 
                transition = {{ease:Power3.easeIn, duration:1.5}} 
        > 
            <Navbar color="#FFFFFF"/> 
            <div className = "About flex relative">
                <div className = "grid">   
                    {/* PHOTO */}
                    <GridItem photo = {autoportrait3}/>

                    {/* DESCRIPTION */}
                    <div className = "Description">
                        <h1> <span className = "hidingSpan"> <span className = "word">WHO IS T ?</span></span></h1>
                        <h2> <span className = "hidingSpan"> <span className = "word">A bit about me </span></span></h2>
                        <p> <span className = "hidingSpan"> <span className = "word">Young photographer from Paris.</span></span> </p>
                        <p> <span className = "hidingSpan"> <span className = "word">To grow old is to give up. </span></span> </p>
                        <p> <span className = "hidingSpan"> <span className = "word">I'll never grow old. </span></span> </p>
                        <p> <span className = "hidingSpan"> <span className = "word">Cheers. </span></span> </p>

                    </div>
                </div> 
                <p className = "Insta absolute"> <span className = "hidingSpan"> <span className = "word"><a href = "https://www.instagram.com/tienot_no_aware/"> Follow me on <AiOutlineInstagram/></a> </span></span> </p>
            </div>
        </motion.div>
    )
}