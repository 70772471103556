// STYLING
import './App.css';

// Pages components
import Homepage from './Homepage/Homepage'
import Grid from './Grid/Grid'
import Gallery from './RealGrid/Gallery/Gallery';
import RealGrid from './RealGrid/RealGrid/RealGrid';
import About from './AboutPage/About';

// React-router components
import Router from './Router'
import { Switch, Route } from 'react-router-dom'

// framer-motion
import { AnimatePresence } from 'framer-motion'

function App(props) {
  const {ssrLocation} = props;

  return (
    <Router ssrLocation={ssrLocation}>
      <div className="App">
        {/* <Navbar /> */}
          <Route 
            render = {({location}) => (

              <AnimatePresence exitBeforeEnter initial = {false}>
                <Switch location= {location} key={location.pathname}>
                  <Route exact path = "/" component = {Homepage}/>
                  <Route exact path = "/grid" render = {() => <Grid />} />
                  <Route exact path = "/realgrid" render = {() => <RealGrid />} />
                  <Route exact path = "/gallery" render = {() => <Gallery />} />
                  <Route exact path = "/about" render = {() => <About />} />
                  <Route path = "*" component  = {Homepage} />

                </Switch>
              </AnimatePresence>

            )}     
          />
      </div>
    </Router>
  );
}

export default App;
