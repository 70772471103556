// HOOKS
import useMousePosition from '../Hooks/useMousePosition'
// GSAP
import gsap, {Power3} from 'gsap'

export default function Move(component){
    let mousePos = useMousePosition();
    let translationVals = {tx: 0, ty: 0}
    
    translationVals.tx = mousePos.x * 0.07;
    translationVals.ty = mousePos.y * 0.05;
    
    gsap.set(`.${component} .grid__item-img`, 
        {
            x: translationVals.tx/10, 
            y: translationVals.ty/10,
            ease: Power3.easeIn
        }, 2);  

    gsap.set(`.${component} .grid__item`,{
        x: translationVals.tx/100, 
        y: translationVals.ty/100,
        ease: Power3.easeIn
    })

}