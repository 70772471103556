import React, {Component} from 'react'

// GSAP Component
import {Power3} from 'gsap'

//Framer-motion components
import {motion} from 'framer-motion'

//React Components
import IntroductionHero from './IntroductionHero'
import PhotoGrid from './PhotoGrid'

// Styling
import "../Utilitaries/Services.css"
import './Grid.css'

class Grid extends Component{
    constructor(props){
        super(props)
        this.state = {}
    }

    render(){
        return (
            <motion.div 
                className = 'Grid'
                animate = {{opacity: 1, x: 0, y: 0}}
                exit = {{x:'-100vw', y: '-100vh'}} 
                transition = {{ease:Power3.easeIn, duration:1}}
            >                   

                {/* <motion.div 
                   className = "absolute pageTransition"    
                   initial = {{x: '0', y: '-200px', opacity: 1,  zIndex: 2000}}
                    animate = {{x:'100vw'}}
                    exit = {{x: '0', y: '-200px', opacity: 1, zIndex: 2000}}
                    transition = {{ease:Power3.easeOut, duration:0.5}}
                /> */}
                <IntroductionHero />
                <PhotoGrid  />
            </motion.div>
        )
    }

}

export default Grid