export default function getLines(){
    // Puts the words between span tags
    getWords()

    //Creates an array with all the lines
    let lines = [];
    let line;
    let intro = document.getElementsByTagName('h1')[0];
    let words = intro.getElementsByTagName('span');

    let lastTop;
    for (let i = 0; i < words.length; i++) {
        let word = words[i];
        if (word.offsetTop !== lastTop) {
        lastTop = word.offsetTop;
        line = [];
        lines.push(line);
        }
        line.push(word);
    }
    return lines;
}

function getWords(){
    const intro = document.getElementsByTagName('h1')[0];
    intro.innerHTML = intro.innerText.split(/\s/).map(function(word) {
      return '<span className = "word asbsolute">' + word + '</span>'
    }).join(' ');
}