import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'

// Framer-Motion Components 
import {motion} from 'framer-motion'

import './Services.css'
import "./Navbar.css"


class Navbar extends Component{
    render(){
        console.log(this.props.color)

        return (
            <motion.div 
                initial = {{opacity: 0}}
                animate = {{opacity: 1}}
                exit= {{opacity: 0}} 
                className = "Navbar" style = {{color: this.props.color}}>
                    <div className = "flex">
                        <div className = "Navbar-Logo"> T </div> 
                        <div>
                            <NavLink exact to = "/" className="NavLink" activeClassName = "activeStyle"  > Home </NavLink>
                            <NavLink exact to = "/gallery" className="NavLink" activeClassName = "activeStyle"  > Work </NavLink>
                            <NavLink exact to = "/about" className="NavLink" activeClassName = "activeStyle"  > About </NavLink>
                        </div>
                    </div>
            </motion.div>
        )
    }
}

export default Navbar